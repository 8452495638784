import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconChevronDown({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--stroke ${className}`} viewBox="0 0 24 24">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M6 9L12 15L18 9" />
            </svg>
        </>
    );
}

IconChevronDown.propTypes = propTypes;
IconChevronDown.defaultProps = defaultProps;

export default IconChevronDown;
