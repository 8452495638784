import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Label from 'atoms/label/Label';
import Input from 'atoms/input/Input';

import './FormGroupInput.scss';

const defaultProps = {
    className: '',
    disabled: false,
    id: '',
    label: '',
    name: '',
    onChange: () => {},
    placeholder: undefined,
    required: false,
    type: 'text',
    value: undefined,
};

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
};

const FormGroupInput = forwardRef(({
    className,
    disabled,
    id,
    label,
    name,
    onChange,
    placeholder,
    required,
    type,
    value,
    ...rest
}, ref) => {
    return (
        <>
            <div className={`m-form-group m-form-group--input ${className}`}>
                <Label
                    className="m-form-group__label"
                    id={id}
                    label={label}
                    required={required}
                />
                <Input
                    className="m-form-group__input"
                    disabled={disabled}
                    id={id}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    ref={ref}
                    required={required}
                    type={type}
                    value={value}
                    {...rest}
                />
            </div>
        </>
    );
});

FormGroupInput.propTypes = propTypes;
FormGroupInput.defaultProps = defaultProps;

export default FormGroupInput;