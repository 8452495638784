import React, { forwardRef, useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import PropTypes from 'prop-types';

import Button from 'atoms/button/Button';
import IconPlay from 'atoms/icon/Play';

import './Video.scss';

const defaultProps = {
    autoPlay: true,
    className: '',
    loop: true,
    videoEmbed: {
        html: '',
    },
    videoUpload: {
        url: '',
    }
};

const propTypes = {
    autoPlay: PropTypes.bool,
    className: PropTypes.string,
    loop: PropTypes.bool,
    videoEmbed: PropTypes.shape({
        html: PropTypes.string,
    }),
    videoUpload: PropTypes.shape({
        url: PropTypes.string,
    }),
};

const Video = forwardRef(({
    autoPlay,
    className,
    loop,
    videoEmbed,
    videoUpload,
}, ref) => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const videoEmbedImage = (videoEmbed?.image) ? videoEmbed?.image : '';
    const videoEmbedProviderName = (videoEmbed?.providerName) ? videoEmbed?.providerName : '';
    const videoEmbedTitle = (videoEmbed?.title) ? videoEmbed?.title : '';
    const videoEmbedUrl = (videoEmbed?.html) ? videoEmbed?.html?.split('src=')[1].split(/[ >]/)[0].slice(1,-1) : '';
    const videoUploadUrl = videoUpload?.url;

    function loadVideo() {
        setIsVideoLoaded(true);
    }

    return (
        <>
            <div className={`a-video ${className}`}>
                {videoEmbedUrl && !videoUploadUrl && (
                    <div className="a-video__embed">
                        {!isVideoLoaded && (
                            <Button aria-label="Play video" className="a-button--unstyled a-video__embed-play-video-button" iconOnly={true} onClick={loadVideo} type="button"><IconPlay /></Button>
                        )}
                        <img alt={videoEmbedTitle} className="a-video__embed-image" loading="lazy" src={videoEmbedImage} />
                        {isVideoLoaded && (
                            <>
                                {videoEmbedProviderName === 'Vimeo' && (
                                    <div className="a-video__embed-video">
                                        <ReactPlayer
                                            controls={true}
                                            height="100%"
                                            playing={isVideoLoaded}
                                            url={videoEmbedUrl}
                                            width="100%"
                                        />
                                    </div>
                                )}
                                {videoEmbedProviderName !== 'Vimeo' && (
                                    <iframe
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen={true}
                                        frameBorder="0"
                                        loading="lazy"
                                        mozallowfullscreen="true"
                                        src={videoEmbedUrl}
                                        title={videoEmbedTitle}
                                        webkitallowfullscreen="true"
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
                {!videoEmbedUrl && videoUploadUrl && (
                    <div className="a-video__upload">
                        <video autoPlay={autoPlay} className="a-video__upload-video" loop={loop} muted={true} playsInline={true} ref={ref} loading="lazy" src={videoUploadUrl} />
                    </div>
                )}
            </div>
        </>
    );
});

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;