import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Link from 'atoms/link/Link';
import Image from 'atoms/image/Image';
import FooterQuickLinks from 'molecules/footerquicklinks/FooterQuickLinks';

import './Footer.scss';

const defaultProps = {
    className: '',
    horizonProgramme: 'Co-funded by the Horizon 2020 programme of the European Union',
    strapline: 'Our intelligent pipeline solution makes businesses safer, cleaner and more efficient.',
};

const propTypes = {
    className: PropTypes.string,
    horizonProgramme: PropTypes.string,
    strapline: PropTypes.string,
};

function useGlobalFooter() {
    const data = useStaticQuery(graphql`
        query GlobalFooter {
            allPrismicSite {
                nodes {
                    data {
                        fo_legal_links_listing {
                            fo_legal_links_link_text {
                                text
                            }
                            fo_legal_links_link_url {
                                link_type
                                type
                                uid
                            }
                        }
                        fo_quick_links_1_heading {
                            text
                        }
                        fo_quick_links_1_heading_link {
                            link_type
                            type
                            uid
                        }
                        fo_quick_links_1_listing {
                            fo_quick_links_link_text {
                                text
                            }
                            fo_quick_links_link_url {
                                link_type
                                type
                                uid
                            }
                        }
                        fo_quick_links_2_heading {
                            text
                        }
                        fo_quick_links_2_heading_link {
                            link_type
                            type
                            uid
                        }
                        fo_quick_links_2_listing {
                            fo_quick_links_link_text {
                                text
                            }
                            fo_quick_links_link_url {
                                link_type
                                type
                                uid
                            }
                        }
                        fo_quick_links_3_heading {
                            text
                        }
                        fo_quick_links_3_heading_link {
                            link_type
                            type
                            uid
                        }
                        fo_quick_links_3_listing {
                            fo_quick_links_link_text {
                                text
                            }
                            fo_quick_links_link_url {
                                link_type
                                type
                                uid
                            }
                        }
                        fo_quick_links_4_heading {
                            text
                        }
                        fo_quick_links_4_heading_link {
                            link_type
                            type
                            uid
                        }
                        fo_quick_links_4_listing {
                            fo_quick_links_link_text {
                                text
                            }
                            fo_quick_links_link_url {
                                link_type
                                url
                            }
                        }
                        fo_logos {
                            fo_logo {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    return data;
}

function Footer({
    className,
    horizonProgramme,
    strapline,
}) {
    const {
        allPrismicSite: {
            nodes
        }
    } = useGlobalFooter();
    const date = new Date();
    const dateYear = date.getFullYear();
    const data = nodes?.[0]?.data;
    const legalLinksListing = data?.fo_legal_links_listing;
    const quickLinks1Heading = data?.fo_quick_links_1_heading?.text;
    const quickLinks1HeadingUrl = data?.fo_quick_links_1_heading_link;
    const quickLinks1Listing = data?.fo_quick_links_1_listing;
    const quickLinks2Heading = data?.fo_quick_links_2_heading?.text;
    const quickLinks2HeadingUrl = data?.fo_quick_links_2_heading_link;
    const quickLinks2Listing = data?.fo_quick_links_2_listing;
    const quickLinks3Heading = data?.fo_quick_links_3_heading?.text;
    const quickLinks3HeadingUrl = data?.fo_quick_links_3_heading_link;
    const quickLinks3Listing = data?.fo_quick_links_3_listing;
    const quickLinks4Heading = data?.fo_quick_links_4_heading?.text;
    const quickLinks4HeadingUrl = data?.fo_quick_links_4_heading_link;
    const quickLinks4Listing = data?.fo_quick_links_4_listing;
    const logos = data?.fo_logos;

    return (
        <>
            <footer className={`o-footer ${className}`}>
                <div className="o-footer-flow-bg">
                    <img className="o-footer-flow-bg__img" src="/images/footer-flow.svg" loading="lazy" />
                </div>
                <div className="o-footer__inner">
                    <div className="l-grid-large l-grid-large--container">
                        <div className="o-footer__brand-container">
                            <div className="o-footer__brand-wrapper">
                                <Link aria-label="Home" className="o-footer__logo-link" to={{link_type: 'Document', type: 'home'}}>
                                    <svg className="o-footer__logo" viewBox="0 0 1000.27 192.12">
                                        <path d="M383.33 180.02c-6.76-6.76-10.14-16.5-10.14-29.21V19.09h39.1v131.97c0 3.91.98 6.88 2.93 8.92 1.96 2.04 4.97 3.06 9.04 3.06h11v27.13h-22.73c-12.7-.01-22.44-3.39-29.2-10.15zM513.71 114.89c4.26-.43 7.17-1.63 8.8-2.93 1.63-1.3 2.44-3.5 2.44-6.6 0-4.72-1.55-8.39-4.64-11-3.1-2.6-7.58-3.91-13.44-3.91-5.54 0-10.1 1.43-13.69 4.28-3.59 2.85-5.62 6.72-6.11 11.61h-38.13c1.14-13.69 6.97-24.4 17.47-32.14 10.51-7.74 24.48-11.61 41.91-11.61 18.57 0 32.46 4.2 41.67 12.59 9.2 8.39 13.81 20.82 13.81 37.27v77.72h-36.9v-18.09h-1.47c-4.24 6.52-9.82 11.49-16.74 14.91-6.93 3.42-14.71 5.13-23.34 5.13-12.87 0-22.93-3.18-30.18-9.53s-10.88-15.15-10.88-26.39c0-8.15 1.96-14.99 5.87-20.53 3.91-5.54 9.94-9.69 18.09-12.46 0-.01 11.9-4.93 45.46-8.32zm3.42 42.65c5.21-5.29 7.82-11.93 7.82-19.92v-4.16c-2.12.65-4.64 1.14-7.58 1.47-2.93.33-5.95.73-9.04 1.22-3.42.49-6.76.98-10.02 1.47s-5.54.98-6.84 1.47c-6.68 2.28-10.02 6.76-10.02 13.44 0 4.24 1.34 7.45 4.03 9.65 2.69 2.2 6.48 3.3 11.36 3.3 8.32 0 15.08-2.65 20.29-7.94zM584.21 64.55h37.39v23.22h.98c3.26-7.33 8.43-13.03 15.52-17.11 7.09-4.07 16.58-6.11 28.47-6.11v35.44h-10.51c-10.59 0-18.7 2.57-24.32 7.7s-8.43 13.73-8.43 25.78v56.7h-39.1V64.55zM682.94 64.55h39.1v125.62h-39.1V64.55zM878.32 64.55h37.39v17.84h.98c4.4-6.35 9.9-11.24 16.5-14.66s14.13-5.13 22.61-5.13c14.5 0 25.54 4.64 33.12 13.93 7.58 9.29 11.36 23.14 11.36 41.55v72.1h-39.35v-72.1c0-8.96-1.75-15.52-5.25-19.67-3.51-4.16-8.92-6.23-16.25-6.23-7.5 0-13.04 2.08-16.62 6.23-3.59 4.15-5.38 10.71-5.38 19.67v72.1h-39.1V64.55zM249.44 19.09h-40.08v171.07h40.08V95.83z"  fill="#727272"/>
                                        <path d="M683.21 7.64h36.89v36.89h-36.89z" fill="#727272" transform="rotate(45.001 701.66 26.076)"/>
                                        <path fill="#FF0000" d="m300.52 96.54 65.1-77.45h-45.86l-65.1 77.45 65.73 93.62h45.86z"/>
                                        <path fill="#1421ff" d="m158.28 112.72-65.1 77.44h45.86l65.1-77.44-65.73-93.63H92.55z"/>
                                        <path fill="#00c500" d="M65.73 112.72.63 190.16h45.86l65.11-77.44-65.74-93.63H0z"/>
                                        <path d="M807.83 114.89c4.26-.43 7.17-1.63 8.8-2.93 1.63-1.3 2.44-3.5 2.44-6.6 0-4.72-1.55-8.39-4.64-11-3.1-2.6-7.58-3.91-13.44-3.91-5.54 0-10.1 1.43-13.69 4.28-3.59 2.85-5.62 6.72-6.11 11.61h-38.13c1.14-13.69 6.97-24.4 17.47-32.14 10.51-7.74 24.48-11.61 41.91-11.61 18.57 0 32.46 4.2 41.67 12.59 9.2 8.39 13.81 20.82 13.81 37.27v77.72h-36.9v-18.09h-1.47c-4.24 6.52-9.82 11.49-16.74 14.91-6.93 3.42-14.71 5.13-23.34 5.13-12.87 0-22.93-3.18-30.18-9.53s-10.88-15.15-10.88-26.39c0-8.15 1.96-14.99 5.87-20.53 3.91-5.54 9.94-9.69 18.09-12.46 0-.01 11.89-4.93 45.46-8.32zm3.42 42.65c5.21-5.29 7.82-11.93 7.82-19.92v-4.16c-2.12.65-4.64 1.14-7.58 1.47-2.93.33-5.95.73-9.04 1.22-3.42.49-6.76.98-10.02 1.47s-5.54.98-6.84 1.47c-6.68 2.28-10.02 6.76-10.02 13.44 0 4.24 1.34 7.45 4.03 9.65 2.69 2.2 6.48 3.3 11.36 3.3 8.31 0 15.07-2.65 20.29-7.94z"  fill="#727272"/>
                                    </svg>
                                </Link>
                                <span className="o-footer__strapline">{strapline}</span>
                            </div>
                            <div className="o-footer__logos-container">
                                {logos && (
                                    <>
                                        {logos.map(node => {
                                            const logo = node?.fo_logo;
                                            const imageAlt = (logo?.alt) ? logo?.alt : '';
                                            const imageHeight = logo?.dimensions?.height;
                                            const imageUrl = logo?.url;
                                            const imageWidth = logo?.dimensions?.width;

                                            return (
                                                <div className="o-footer__logos-item" key={`footer-logo-${imageAlt}`} style={{ aspectRatio: `${imageWidth / imageHeight}` }}>
                                                    <Image
                                                        className="o-footer__logos-image"
                                                        image={{
                                                            alt: imageAlt,
                                                            dimensions: {
                                                                height: imageHeight,
                                                                width: imageWidth,
                                                            },
                                                            url: imageUrl,
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                        </div>
                        {quickLinks1Listing && (
                            <FooterQuickLinks
                                className="o-footer__quick-links-container"
                                heading={quickLinks1Heading}
                                headingUrl={quickLinks1HeadingUrl}
                                id="1"
                                listing={quickLinks1Listing}
                            />
                        )}
                        {quickLinks2Listing && (
                            <FooterQuickLinks
                                className="o-footer__quick-links-container"
                                heading={quickLinks2Heading}
                                headingUrl={quickLinks2HeadingUrl}
                                id="2"
                                listing={quickLinks2Listing}
                            />
                        )}
                        {quickLinks3Listing && (
                            <FooterQuickLinks
                                className="o-footer__quick-links-container"
                                heading={quickLinks3Heading}
                                headingUrl={quickLinks3HeadingUrl}
                                id="3"
                                listing={quickLinks3Listing}
                            />
                        )}
                        {quickLinks4Listing && (
                            <FooterQuickLinks
                                className="o-footer__quick-links-container"
                                heading={quickLinks4Heading}
                                headingUrl={quickLinks4HeadingUrl}
                                id="4"
                                listing={quickLinks4Listing}
                                showLinkedInFollow={true}
                            />
                        )}
                        {legalLinksListing && (
                            <div className="o-footer__legal-links-container">
                                <ul className="o-footer__legal-links-list">
                                    {legalLinksListing.map(node => {
                                        const linkText = node?.fo_legal_links_link_text?.text;
                                        const linkUrl = node?.fo_legal_links_link_url;

                                        return (
                                            <li className="o-footer__legal-links-item" key={linkText}>
                                                {(linkText && linkUrl) && (
                                                    <Link className="o-footer__legal-links-link" partiallyActive={true} to={linkUrl}>{linkText}</Link>
                                                )}
                                            </li>
                                        );
                                    })}
                                    <li className="o-footer__legal-links-item">&copy; {dateYear} Klarian</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </footer>
        </>
    );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
