import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

import Link from 'atoms/link/Link';

import './Content.scss';

const defaultProps = {
    className: '',
    content: [],
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
};

function Content({
    className,
    content,
}) {
    return (
        <>
            <div className={`a-content ${className}`}>
                <RichText
                    render={content}
                    serializeHyperlink={(type, element, content, children, index) => {
                        return (
                            <Link key={index} to={element.data}>{content}</Link>
                        );
                    }}
                />
            </div>
        </>
    );
}

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;