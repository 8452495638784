import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { default as cx } from 'clsx';

import Link from 'atoms/link/Link';

import './Button.scss';

const defaultProps = {
    className: '',
    children: null,
    iconOnly: false,
    to: {},
    type: 'button',
};

const propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    iconOnly: PropTypes.bool,
    to: PropTypes.object,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

/* eslint-disable react/button-has-type */
const Button = forwardRef(({
    className,
    children,
    iconOnly,
    to,
    type,
    ...rest
}, ref) => {
    const iconOnlyClassName = (iconOnly) ? 'a-button--square' : '';
    const classNames = cx('a-button', iconOnlyClassName, className);
    const isButton = Object.keys(to).length === 0 && to.constructor === Object;

    if (!isButton) {
        return (
            <Link className={classNames} ref={ref} to={to} {...rest}>
                {children}
            </Link>
        );
    }

    return (
        <button className={classNames} ref={ref} type={type} {...rest}>
            {children}
        </button>
    );
});
/* eslint-enable react/button-has-type */

Button.displayName = 'Button';
Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;