import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconBurger({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--stroke ${className}`} viewBox="0 0 24 24">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M3 12H21" />
                <path d="M3 6H21" />
                <path d="M3 18H21" />
            </svg>
        </>
    );
}

IconBurger.propTypes = propTypes;
IconBurger.defaultProps = defaultProps;

export default IconBurger;
