import React from 'react';
import PropTypes from 'prop-types';

import './Image.scss';

const defaultProps = {
    className: '',
    image: {},
    lazy: true,
    size: 'medium',
};

const propTypes = {
    className: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
        }),
        url: PropTypes.string,
    }),
    size: PropTypes.string,
};

function getImageUrl(imageUrl, size, quality, type) {
    const imagePath =
      type === 'webp'
        ? `${imageUrl}&fm=webp&lossless=true&q=${quality}`
        : `${imageUrl}&q=${quality}`;
  
    switch (size) {
        case 'xxlarge':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=1280 1280w, ${imagePath}&w=1920 1920w, ${imagePath}&w=2560 2560w`;
        case 'xlarge':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=1280 1280w, ${imagePath}&w=1920 1920w`;
        case 'large':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w, ${imagePath}&w=1280 1280w`;
        case 'medium':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w, ${imagePath}&w=960 960w`;
        case 'small':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w, ${imagePath}&w=768 768w`;
        case 'xsmall':
            return `${imagePath}&w=375 375w, ${imagePath}&w=480 480w`;
        case 'xxsmall':
            return `${imagePath}&w=375 375w`;
        default:
            return `${imagePath}&w=${size} ${size}w`;
    }
}
  
function getSizes(size) {
    switch (size) {
        case 'xxlarge':
            return '2560px';
        case 'xlarge':
            return '1920px';
        case 'large':
            return '1280px';
        case 'medium':
            return '960px';
        case 'small':
            return '768px';
        case 'xsmall':
            return '480px';
        case 'xxsmall':
            return '375px';
        default:
            return `${size}px`;
    }
};

function Image({
    className,
    image,
    lazy,
    size,
}) {
    if (size === '' || size.length === 0) {
        size = 'medium'
    }
    const imageAlt = image.alt ? image.alt : '';
    const imageHeight = image?.dimensions?.height;
    const imageUrl = image?.url;
    const imageWidth = image?.dimensions?.width;
    const imageAspectRatio = (imageHeight / imageWidth) * 100;
    const extension = imageUrl.split('.').pop().split('?')[0];
    const bitmapImagePath = extension !== 'svg' ? getImageUrl(imageUrl, size, 85, 'bitmap') : '';
    const webpImagePath = extension !== 'svg' ? getImageUrl(imageUrl, size, 85, 'webp') : '';
    const sizes = getSizes(size);

    return (
        <>
            <div className={`a-image ${className}`}>
                <div className='a-image__container' style={{ paddingTop: `${imageAspectRatio}%` }}>
                    {imageUrl && (
                        <>
                            {extension !== 'svg' && (
                                <div className='a-image__image'>
                                    <picture>
                                        <source
                                            srcSet={webpImagePath}
                                            sizes={`(min-width: ${sizes}) ${sizes}, 100vw`}
                                            type='image/webp'
                                        />
                                        <img
                                            alt={imageAlt}
                                            loading={lazy ? 'lazy' : 'eager'}
                                            src={`${imageUrl}&q=65`}
                                            srcSet={bitmapImagePath}
                                            sizes={`(min-width: ${sizes}) ${sizes}, 100vw`}
                                        />
                                    </picture>
                                </div>
                            )}
                            {extension === 'svg' && (
                                <img
                                    alt={imageAlt}
                                    className='a-image__image'
                                    src={imageUrl}
                                    loading={lazy ? 'lazy' : 'eager'}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
