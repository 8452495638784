import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconPlay({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 24 24">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M23 12L5.86747 22.3923L5.86747 1.6077L23 12Z" />
            </svg>
        </>
    );
}

IconPlay.propTypes = propTypes;
IconPlay.defaultProps = defaultProps;

export default IconPlay;
