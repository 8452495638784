import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconServer({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--stroke ${className}`} viewBox="0 0 24 24">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M2 4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V8C22 9.10457 21.1046 10 20 10H4C2.89543 10 2 9.10457 2 8V4Z" />
                <path d="M2 16C2 14.8954 2.89543 14 4 14H20C21.1046 14 22 14.8954 22 16V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V16Z" />
                <circle cx="6" cy="6" r="1" fill="currentColor" stroke="none" />
                <circle cx="6" cy="18" r="1" fill="currentColor" stroke="none" />
            </svg>
        </>
    );
}

IconServer.propTypes = propTypes;
IconServer.defaultProps = defaultProps;

export default IconServer;
