import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { default as cx } from 'clsx';

import Vimeo from '@u-wave/react-vimeo';
import Button from 'atoms/button/Button';
import IconClose from 'atoms/icon/Close';
import IconPlay from 'atoms/icon/Play';
import Image from 'atoms/image/Image';
import Video from 'atoms/video/Video';

import './Masthead.scss';

const defaultProps = {
    className: '',
    description: '',
    heading: '',
    image: {},
    video: {},
};

const propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    heading: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.objectOf(PropTypes.number),
        url: PropTypes.string,
    }),
    video: PropTypes.shape({
        url: PropTypes.string,
    }),
    videoEmbed: PropTypes.string,
};

function Masthead({
    className,
    description,
    heading,
    image,
    video,
    videoEmbed,
}) {
    const [isVideoOverlayOpen, setIsVideoOverlayOpen] = useState(false);
    const videoOverlayOpenClassName = isVideoOverlayOpen ? 'is-active' : '';
    const videoEmbedPaused = isVideoOverlayOpen ? false : true;
    const imageAlt = (image.alt) ? image.alt : '';
    const imageHeight = image?.dimensions?.height;
    const imageUrl = image?.url;
    const imageWidth = image?.dimensions?.width;
    const videoUploadUrl = video?.url;
    const imageClassName = (imageUrl) ? 'o-masthead--background-image' : '';
    const videoClassName = (videoUploadUrl) ? 'o-masthead--background-video' : '';
    const gridClassName = (imageUrl || videoUploadUrl) ? 'l-grid-large l-grid-large--container' : 'l-grid l-grid--container';
    const classNames = cx('o-masthead', imageClassName, videoClassName, className);

    function toggleVideoOverlay() {
        setIsVideoOverlayOpen(!isVideoOverlayOpen);
    }

    return (
        <>
            <div className={classNames}>
                <div className={gridClassName}>
                    {(heading || description) && (
                        <div className="o-masthead__content-container">
                            {heading && (
                                <h1 className="o-masthead__heading">{heading}</h1>
                            )}
                            {videoEmbed && (
                                <Button aria-label="Play full video" className="a-button--unstyled o-masthead__play-video-button" iconOnly={true} onClick={toggleVideoOverlay} type="button"><IconPlay /></Button>
                            )}
                            {description && (
                                <p className="o-masthead__description">{description}</p>
                            )}
                        </div>
                    )}
                    {(imageUrl || videoUploadUrl) && (
                        <div className="o-masthead__media-container">
                            {imageUrl && (
                                <Image
                                    className="o-masthead__image"
                                    image={{
                                        alt: imageAlt,
                                        dimensions: {
                                            height: imageHeight,
                                            width: imageWidth,
                                        },
                                        url: imageUrl,
                                    }}
                                    size='xlarge'
                                />
                            )}
                            {videoUploadUrl && (
                                <Video
                                    className="o-masthead__video"
                                    videoUpload={video}
                                />
                            )}
                        </div>
                    )}
                </div>
                {videoEmbed && (
                    <>
                        <div className={`o-masthead__video-embed-container ${videoOverlayOpenClassName}`} onClick={toggleVideoOverlay}>
                            <Button aria-label="Close video" className="a-button--small o-masthead__video-embed-close-button" iconOnly={true} onClick={toggleVideoOverlay}>
                                <IconClose className="o-masthead__video-embed-close-icon" />
                            </Button>
                            <div className="l-grid-large l-grid-large--container">
                                <div className="o-masthead__video-embed-wrapper">
                                    {isVideoOverlayOpen && (
                                        <Vimeo
                                            autoplay={true}
                                            className="o-masthead__video-embed"
                                            paused={false}
                                            video={videoEmbed}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

Masthead.propTypes = propTypes;
Masthead.defaultProps = defaultProps;

export default Masthead;
