import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Cookies from 'universal-cookie';
import { default as cx } from 'clsx';
import Button from 'atoms/button/Button';
import Content from 'atoms/content/Content';

import './Cookiebar.scss';

const defaultProps = {
    buttonText: 'Accept & Close',
    className: '',
};

const propTypes = {
    buttonText: PropTypes.string,
    className: PropTypes.string,
};

function useGlobalCookiebar() {
    const data = useStaticQuery(graphql`
        query GlobalCookiebar {
            allPrismicSite {
                nodes {
                    data {
                        cb_message {
                            raw
                        }
                    }
                }
            }
        }
    `);

    return data;
}

function Cookiebar({
    buttonText,
    className,
}) {
    const {
        allPrismicSite: {
            nodes
        }
    } = useGlobalCookiebar();
    const cookieName = 'KlarianLTDCookiePolicy';
    const cookies = new Cookies();
    const [isCookiebarHidden, setIsCookiebarHidden] = useState(true);
    const hiddenClassName = isCookiebarHidden ? 'is-hidden' : '';
    const classNames = cx('o-cookiebar', hiddenClassName, className);
    const message = nodes?.[0]?.data?.cb_message?.raw;

    useEffect(function() {
        if (!cookies.get(cookieName)) {
            setIsCookiebarHidden(false);
        }
    }, []);

    function dismissCookiebar() {
        setIsCookiebarHidden(!isCookiebarHidden);
        cookies.set(cookieName, 'true', { path: '/' });
    }

    return (
        <>
            <div className={classNames}>
                <div className="l-grid l-grid--container">
                    <div className="o-cookiebar__container">
                        {message && (
                            <Content className="o-cookiebar__text" content={message} />
                        )}
                        <Button className="a-button--secondary o-cookiebar__button a-button--light-fill a-button--small" onClick={dismissCookiebar}>{buttonText}</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

Cookiebar.propTypes = propTypes;
Cookiebar.defaultProps = defaultProps;

export default Cookiebar;
