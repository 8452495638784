import React from 'react';
import PropTypes from 'prop-types';

import SEO from 'layout/seo/SEO';
import Cookiebar from 'organisms/cookiebar/Cookiebar';
import Footer from 'organisms/footer/Footer';
import Header from 'organisms/header/Header';

const defaultProps = {
    className: '',
    children: null,
    preloadAssets: [],
    seoDescription: '',
    seoImage: null,
    seoTitle: '',
};

const propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    pathname: PropTypes.string.isRequired,
    preloadAssets: PropTypes.array,
    seoDescription: PropTypes.string,
    seoImage: PropTypes.string,
    seoTitle: PropTypes.string,
};

function Shell({
    className,
    children,
    pathname,
    preloadAssets,
    seoDescription,
    seoImage,
    seoTitle,
}) {
    return (
        <div className={`l-grid l-shell ${className}`}>
            <SEO
                pageDescription={seoDescription}
                pageSocialImage={seoImage}
                pageTitle={seoTitle}
                pathname={pathname}
                preloadAssets={preloadAssets}
            />
            <div className="l-shell__header">
                <Cookiebar />
            </div>
            <div className="l-shell__main">
                <Header />
                {children}
            </div>
            <Footer className="l-shell__footer" />
        </div>
    );
}

Shell.propTypes = propTypes;
Shell.defaultProps = defaultProps;

export default Shell;