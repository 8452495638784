import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconArrowRight({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--stroke ${className}`} viewBox="0 0 24 24">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M4 12H20" />
                <path d="M14 6L20 12L14 18" />
            </svg>
        </>
    );
}

IconArrowRight.propTypes = propTypes;
IconArrowRight.defaultProps = defaultProps;

export default IconArrowRight;
