const linkResolverSitemap = require('./linkResolverSitemap.json');

const linkResolver = ({ node, key, value } = {}) => doc => {
	const linkType = doc.link_type;

	function returnFullPageSlug(uid) {
		const object = linkResolverSitemap.pages.find(obj => obj.uid === uid);
		return (object.slug) ? object.slug : `/${doc.uid}`;
	}

	if (linkType === 'Media' || linkType === 'Web') {
    	return doc.url;
	}
	else if (linkType === 'Document') {
    	switch(doc.type) {
			case "home": return '/';
			case "blog_article": return returnFullPageSlug(doc.uid);
			case "blog_listing": return `/${doc.uid}`;
			case "blog_pagination": return `${doc.uid}`;
      		case "content": return returnFullPageSlug(doc.uid);
      		default: return "/";
    	}
  	}
};

export default linkResolver;