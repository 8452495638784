import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { useStaticQuery, graphql } from 'gatsby';

import Button from 'atoms/button/Button';
import IconClose from 'atoms/icon/Close';
import IconSearch from 'atoms/icon/Search';
import Link from 'atoms/link/Link';
import Nav from 'molecules/nav/Nav';
import FormSearch from 'organisms/formsearch/FormSearch';

import './Header.scss';

const defaultProps = {
	className: '',
};

const propTypes = {
	className: PropTypes.string,
};

function useGlobalHeader() {
    const data = useStaticQuery(graphql`
        query GlobalHeader {
            allPrismicSite {
                nodes {
                    data {
                        he_menu {
                            he_link_text {
                                text
                            }
                            he_link_url {
                                link_type
                                type
                                uid
                            }
                        }
                        he_nav_dropdown_1_description {
                            text
                        }
                        he_nav_dropdown_1_link_text {
                            text
                        }
                        he_nav_dropdown_1_link_url {
                            link_type
                            type
                            uid
                        }
                        he_nav_dropdown_1_link_group_1_heading {
                            text
                        }
                        he_nav_dropdown_1_link_group_1_links {
                            he_link_text {
                                text
                            }
                            he_link_url {
                                link_type
                                type
                                uid
                            }
                        }
                        he_nav_dropdown_1_link_group_2_heading {
                            text
                        }
                        he_nav_dropdown_1_link_group_2_links {
                            he_link_text {
                                text
                            }
                            he_link_url {
                                link_type
                                type
                                uid
                            }
                        }
                        he_nav_dropdown_1_link_group_3_heading {
                            text
                        }
                        he_nav_dropdown_1_link_group_3_links {
                            he_link_text {
                                text
                            }
                            he_link_url {
                                link_type
                                type
                                uid
                            }
                            he_live
                        }
                        he_nav_dropdown_2_description {
                            text
                        }
                        he_nav_dropdown_2_link_text {
                            text
                        }
                        he_nav_dropdown_2_link_url {
                            link_type
                            type
                            uid
                        }
                        he_nav_dropdown_2_link_group_links {
                            he_link_description {
                                text
                            }
                            he_link_icon
                            he_link_text {
                                text
                            }
                            he_link_url {
                                link_type
                                type
                                uid
                            }
                        }
                        he_nav_dropdown_3_description {
                            text
                        }
                        he_nav_dropdown_3_link_text {
                            text
                        }
                        he_nav_dropdown_3_link_url {
                            link_type
                            type
                            uid
                        }
                        he_nav_dropdown_3_link_group_links {
                            he_link_description {
                                text
                            }
                            he_link_icon
                            he_link_text {
                                text
                            }
                            he_link_url {
                                link_type
                                type
                                uid
                            }
                        }
                    }
                }
            }
        }
    `);

    return data;
}

function createMenuDataStructure(data) {
    let menu = [];


    data?.he_menu.forEach((node, index) => {
        let dropdown;

        if (index === 0) {
            dropdown = {
                description: data?.he_nav_dropdown_1_description?.text,
                linkText: data?.he_nav_dropdown_1_link_text?.text,
                linkUrl: data?.he_nav_dropdown_1_link_url,
                dropdownLinks1Heading: data?.he_nav_dropdown_1_link_group_1_heading?.text,
                dropdownLinks1Listing: [],
                dropdownLinks2Heading: data?.he_nav_dropdown_1_link_group_2_heading?.text,
                dropdownLinks2Listing: [],
                dropdownLinks3Heading: data?.he_nav_dropdown_1_link_group_3_heading?.text,
                dropdownLinks3Listing: [],
            }

            data?.he_nav_dropdown_1_link_group_1_links.forEach((node) => {
                dropdown.dropdownLinks1Listing.push({
                    text: node?.he_link_text?.text,
                    url: node?.he_link_url,
                });
            });

            data?.he_nav_dropdown_1_link_group_2_links.forEach((node) => {
                dropdown.dropdownLinks2Listing.push({
                    text: node?.he_link_text?.text,
                    url: node?.he_link_url,
                });
            });

            data?.he_nav_dropdown_1_link_group_3_links.forEach((node) => {
                dropdown.dropdownLinks3Listing.push({
                    live: node?.he_live,
                    text: node?.he_link_text?.text,
                    url: node?.he_link_url,
                });
            });
        }

        if (index === 1) {
            dropdown = {
                description: data?.he_nav_dropdown_2_description?.text,
                linkText: data?.he_nav_dropdown_2_link_text?.text,
                linkUrl: data?.he_nav_dropdown_2_link_url,
                dropdownLinks1Listing: [],
            }

            data?.he_nav_dropdown_2_link_group_links.forEach((node) => {
                dropdown.dropdownLinks1Listing.push({
                    description: node?.he_link_description?.text,
                    icon: node?.he_link_icon,
                    text: node?.he_link_text?.text,
                    url: node?.he_link_url,
                });
            });
        }
        
        menu.push({
            dropdown: dropdown,
            text: node?.he_link_text?.text,
            url: node?.he_link_url,
        });
    });

    return menu;
}

function Header({
	className,
}) {
    const {
        allPrismicSite: {
            nodes
        }
    } = useGlobalHeader();
    const [ref, inView, entry] = useInView({
        threshold: 0,
    });
    const stickyClassName = (entry && !inView) ? 'is-sticky' : '';
    const menu = createMenuDataStructure(nodes?.[0]?.data);
    const desktopBreakpoint = typeof window !== 'undefined' ? window.matchMedia('(min-width: 960px)') : null;
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchToggleButtonText, setSearchToggleButtonText] = useState('Open Search');
    const searchOpenClassName = isSearchOpen ? 'is-active' : '';
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuToggleButtonText, setMenuToggleButtonText] = useState('Open Menu');
	const menuOpenClassName = isMenuOpen ? 'is-active' : '';
	const [activeDropdown, setActiveDropdown] = useState(null);
    const activeDropdownClassName = 'is-active';
    const [isActiveOverlay, setIsActiveOverlay] = useState(false);
    const activeOverlayClassName = isActiveOverlay ? 'is-active': '';
    const searchInputRef = useRef(null);

	function toggleSearch() {
        setActiveDropdown(null);
        setIsMenuOpen(false);
        setMenuToggleButtonText('Open Menu');
        setIsSearchOpen(!isSearchOpen);
        isSearchOpen ? setSearchToggleButtonText('Open Search') : setSearchToggleButtonText('Close Search');
    }

    function toggleMenu() {
        setIsSearchOpen(false);
        setSearchToggleButtonText('Open Search');
        setIsMenuOpen(!isMenuOpen);
        isMenuOpen ? setMenuToggleButtonText('Open Menu') : setMenuToggleButtonText('Close Menu');
	}

	function handleActiveDropdown(event) {
        setIsSearchOpen(false);
		event.target.classList.contains(activeDropdownClassName) ? setActiveDropdown(null) : setActiveDropdown(event.target.id);
    }

    function closePopups() {
        setActiveDropdown(null);
        setIsMenuOpen(false);
        setMenuToggleButtonText('Open Menu');
        setIsSearchOpen(false);
        setSearchToggleButtonText('Open Search');
    }

    function focusInput() {
        if (isSearchOpen) {
            searchInputRef.current.focus();
        }
    }

    useEffect(() => {
        if (activeDropdown || isMenuOpen || isSearchOpen) {
            setIsActiveOverlay(true);
        } else {
            setIsActiveOverlay(false);
        }

        if (!isMenuOpen && !desktopBreakpoint.matches) {
            setActiveDropdown(null);
        }
    }, [activeDropdown, isMenuOpen, isSearchOpen])

	return (
		<>
			<header className={`o-header ${className} ${activeOverlayClassName} ${stickyClassName}`}>
                <a href="#main-content">Skip to content</a>
                <div className="o-header__nav-container">
                    <div className="l-grid-large l-grid-large--container">
                        <div className="o-header__nav-wrapper">
                            <Link aria-label="Home" className="o-header__logo-link" to={{link_type: 'Document', type: 'home'}}>
                                <svg className="o-header__logo" viewBox="0 0 1000.27 192.12">
                                    <path d="M383.33 180.02c-6.76-6.76-10.14-16.5-10.14-29.21V19.09h39.1v131.97c0 3.91.98 6.88 2.93 8.92 1.96 2.04 4.97 3.06 9.04 3.06h11v27.13h-22.73c-12.7-.01-22.44-3.39-29.2-10.15zM513.71 114.89c4.26-.43 7.17-1.63 8.8-2.93 1.63-1.3 2.44-3.5 2.44-6.6 0-4.72-1.55-8.39-4.64-11-3.1-2.6-7.58-3.91-13.44-3.91-5.54 0-10.1 1.43-13.69 4.28-3.59 2.85-5.62 6.72-6.11 11.61h-38.13c1.14-13.69 6.97-24.4 17.47-32.14 10.51-7.74 24.48-11.61 41.91-11.61 18.57 0 32.46 4.2 41.67 12.59 9.2 8.39 13.81 20.82 13.81 37.27v77.72h-36.9v-18.09h-1.47c-4.24 6.52-9.82 11.49-16.74 14.91-6.93 3.42-14.71 5.13-23.34 5.13-12.87 0-22.93-3.18-30.18-9.53s-10.88-15.15-10.88-26.39c0-8.15 1.96-14.99 5.87-20.53 3.91-5.54 9.94-9.69 18.09-12.46 0-.01 11.9-4.93 45.46-8.32zm3.42 42.65c5.21-5.29 7.82-11.93 7.82-19.92v-4.16c-2.12.65-4.64 1.14-7.58 1.47-2.93.33-5.95.73-9.04 1.22-3.42.49-6.76.98-10.02 1.47s-5.54.98-6.84 1.47c-6.68 2.28-10.02 6.76-10.02 13.44 0 4.24 1.34 7.45 4.03 9.65 2.69 2.2 6.48 3.3 11.36 3.3 8.32 0 15.08-2.65 20.29-7.94zM584.21 64.55h37.39v23.22h.98c3.26-7.33 8.43-13.03 15.52-17.11 7.09-4.07 16.58-6.11 28.47-6.11v35.44h-10.51c-10.59 0-18.7 2.57-24.32 7.7s-8.43 13.73-8.43 25.78v56.7h-39.1V64.55zM682.94 64.55h39.1v125.62h-39.1V64.55zM878.32 64.55h37.39v17.84h.98c4.4-6.35 9.9-11.24 16.5-14.66s14.13-5.13 22.61-5.13c14.5 0 25.54 4.64 33.12 13.93 7.58 9.29 11.36 23.14 11.36 41.55v72.1h-39.35v-72.1c0-8.96-1.75-15.52-5.25-19.67-3.51-4.16-8.92-6.23-16.25-6.23-7.5 0-13.04 2.08-16.62 6.23-3.59 4.15-5.38 10.71-5.38 19.67v72.1h-39.1V64.55zM249.44 19.09h-40.08v171.07h40.08V95.83z"  fill="#727272"/>
                                    <path d="M683.21 7.64h36.89v36.89h-36.89z" fill="#727272" transform="rotate(45.001 701.66 26.076)"/>
                                    <path fill="#FF0000" d="m300.52 96.54 65.1-77.45h-45.86l-65.1 77.45 65.73 93.62h45.86z"/>
                                    <path fill="#1421ff" d="m158.28 112.72-65.1 77.44h45.86l65.1-77.44-65.73-93.63H92.55z"/>
                                    <path fill="#00c500" d="M65.73 112.72.63 190.16h45.86l65.11-77.44-65.74-93.63H0z"/>
                                    <path d="M807.83 114.89c4.26-.43 7.17-1.63 8.8-2.93 1.63-1.3 2.44-3.5 2.44-6.6 0-4.72-1.55-8.39-4.64-11-3.1-2.6-7.58-3.91-13.44-3.91-5.54 0-10.1 1.43-13.69 4.28-3.59 2.85-5.62 6.72-6.11 11.61h-38.13c1.14-13.69 6.97-24.4 17.47-32.14 10.51-7.74 24.48-11.61 41.91-11.61 18.57 0 32.46 4.2 41.67 12.59 9.2 8.39 13.81 20.82 13.81 37.27v77.72h-36.9v-18.09h-1.47c-4.24 6.52-9.82 11.49-16.74 14.91-6.93 3.42-14.71 5.13-23.34 5.13-12.87 0-22.93-3.18-30.18-9.53s-10.88-15.15-10.88-26.39c0-8.15 1.96-14.99 5.87-20.53 3.91-5.54 9.94-9.69 18.09-12.46 0-.01 11.89-4.93 45.46-8.32zm3.42 42.65c5.21-5.29 7.82-11.93 7.82-19.92v-4.16c-2.12.65-4.64 1.14-7.58 1.47-2.93.33-5.95.73-9.04 1.22-3.42.49-6.76.98-10.02 1.47s-5.54.98-6.84 1.47c-6.68 2.28-10.02 6.76-10.02 13.44 0 4.24 1.34 7.45 4.03 9.65 2.69 2.2 6.48 3.3 11.36 3.3 8.31 0 15.07-2.65 20.29-7.94z"  fill="#727272"/>
                                </svg>
                            </Link>
                            <Nav
                                activeDropdown={activeDropdown}
                                activeDropdownClassName={activeDropdownClassName}
                                className="o-header__nav"
                                handleActiveDropdown={handleActiveDropdown}
                                isMenuOpen={isMenuOpen}
                                menu={menu}
                                menuOpenClassName={menuOpenClassName}
                                menuToggleButtonText={menuToggleButtonText}
                                toggleMenu={toggleMenu}
                            />
                            <Button aria-label={searchToggleButtonText} className={`a-button--unstyled a-button--small o-header__search-toggle-button ${searchOpenClassName}`} iconOnly={true} onClick={toggleSearch}>
                                {!isSearchOpen && (
                                    <IconSearch className="o-header__search-toggle-icon" />
                                )}
                                {isSearchOpen && (
                                    <IconClose className="o-header__search-toggle-icon" />
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={`o-header__search-container ${searchOpenClassName}`} onTransitionEnd={focusInput}>
                    <div className="l-grid-large l-grid-large--container">
                        <div className="o-header__search-wrapper">
                            {isSearchOpen && (
                                <FormSearch className="o-header__search-form" ref={searchInputRef} />
                            )}
                        </div>
                    </div>
                </div>
                <div className={`o-header__overlay ${activeOverlayClassName}`} onClick={closePopups}></div>
			</header>
            <div className="o-header-sticky-point" ref={ref} />
		</>
	);
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
