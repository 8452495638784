import React from 'react';
import PropTypes from 'prop-types';

import './Label.scss';

const defaultProps = {
	className: '',
	required: false,
};

const propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool,
};

function Label({
    className,
    id,
	label,
	required,
}) {
    return (
        <>
            <label className={`a-label ${className}`} htmlFor={id}>
				{label}
				{!required && (
					<span class="a-label__note">(optional)</span>
				)}
			</label>
        </>
    );
}

Label.propTypes = propTypes;
Label.defaultProps = defaultProps;

export default Label;
