import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconTarget({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 1080 1080">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M529.7 1052.9 0 376.3 270 27.1h540l270 349.2-550.3 676.6zm313.2-676.4L691.4 225.1 540 376.5 691.4 528l151.5-151.5z"/>
            </svg>
        </>
    );
}

IconTarget.propTypes = propTypes;
IconTarget.defaultProps = defaultProps;

export default IconTarget;
