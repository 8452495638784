import React from 'react';
import PropTypes from 'prop-types';
import { default as cx } from 'clsx';

import Button from 'atoms/button/Button';
import IconArrowRight from 'atoms/icon/ArrowRight';
import IconChevronLeft from 'atoms/icon/ChevronLeft';
import Link from 'atoms/link/Link';
import NavDropdownLinks from 'molecules/navdropdownlinks/NavDropdownLinks';

import './NavDropdown.scss';

const defaultProps = {
    className: '',
    description: '',
    handleActiveDropdown: () => {},
    dropdownLinks1Heading: '',
    dropdownLinks1Listing: [],
    dropdownLinks2Heading: '',
    dropdownLinks2Listing: [],
    dropdownLinks3Heading: '',
    dropdownLinks3Listing: [],
    linkText: '',
    linkUrl: {},
    mobileCloseDropdownButtonText: 'Menu',
};

const propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    handleActiveDropdown: PropTypes.func,
    dropdownLinks1Heading: PropTypes.string,
    dropdownLinks1Listing: PropTypes.arrayOf(PropTypes.object),
    dropdownLinks2Heading: PropTypes.string,
    dropdownLinks2Listing: PropTypes.arrayOf(PropTypes.object),
    dropdownLinks3Heading: PropTypes.string,
    dropdownLinks3Listing: PropTypes.arrayOf(PropTypes.object),
    linkText: PropTypes.string,
    linkUrl: PropTypes.object,
    mobileCloseDropdownButtonText: PropTypes.string,
};

function NavDropdown({
    className,
    description,
    handleActiveDropdown,
    dropdownLinks1Heading,
    dropdownLinks1Listing,
    dropdownLinks2Heading,
    dropdownLinks2Listing,
    dropdownLinks3Heading,
    dropdownLinks3Listing,
    linkText,
    linkUrl,
    mobileCloseDropdownButtonText,
}) {
    const showDropdownLinks1 = dropdownLinks1Listing.length > 0;
    const showDropdownLinks2 = dropdownLinks2Listing.length > 0;
    const showDropdownLinks3 = dropdownLinks3Listing.length > 0;
    const singleDropdownClassName = (!showDropdownLinks2 && !showDropdownLinks3) ? 'm-nav-dropdown--single-dropdown-list' : '';
    const classNames = cx('m-nav-dropdown', singleDropdownClassName, className);

	return (
		<>
            <div className={classNames}>
                <div className="m-nav-dropdown__intro-container">
                    {description && (
                        <p className="m-nav-dropdown__intro-description">{description}</p>
                    )}
                    <Button className="a-button--unstyled m-nav-dropdown__mobile-close-dropdown-button" onClick={handleActiveDropdown} type="button">
                        <IconChevronLeft className="m-nav-dropdown__mobile-close-dropdown-button-icon" />
                        {mobileCloseDropdownButtonText}
                    </Button>
                    {(linkText && linkUrl) && (
                        <div className="m-nav-dropdown__intro-link-container">
                            <Link className="m-nav-dropdown__intro-link" to={linkUrl}>
                                {linkText}
                                <IconArrowRight className="m-nav-dropdown__intro-link-icon" />
                            </Link>
                        </div>
                    )}
                </div>
                <div className="m-nav-dropdown__links-container">
                    {showDropdownLinks1 && (
                        <NavDropdownLinks
                            className="m-nav-dropdown__links-wrapper"
                            heading={dropdownLinks1Heading}
                            listing={dropdownLinks1Listing}
                        />
                    )}
                    {showDropdownLinks2 && (
                        <NavDropdownLinks
                            className="m-nav-dropdown__links-wrapper"
                            heading={dropdownLinks2Heading}
                            listing={dropdownLinks2Listing}
                        />
                    )}
                    {showDropdownLinks3 && (
                        <NavDropdownLinks
                            className="m-nav-dropdown__links-wrapper"
                            heading={dropdownLinks3Heading}
                            listing={dropdownLinks3Listing}
                        />
                    )}
                </div>
            </div>
		</>
	);
}

NavDropdown.propTypes = propTypes;
NavDropdown.defaultProps = defaultProps;

export default NavDropdown;
