import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconUsers({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 1080 1080">
                {title && (
                    <title>{title}</title>
                )}
                <path d="m856 581.9-316 316-316-316C116 672.3 47.1 808 47.1 959.9c0 41.4 5.2 81.6 14.8 120.1H1018c9.6-38.4 14.8-78.6 14.8-120.1.1-151.9-68.8-287.6-176.8-378z" />
                <circle cx="540" cy="298.2" r="297.8" />
            </svg>
        </>
    );
}

IconUsers.propTypes = propTypes;
IconUsers.defaultProps = defaultProps;

export default IconUsers;
