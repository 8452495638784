import React from 'react';
import PropTypes from 'prop-types';

import Link from 'atoms/link/Link';

import './FooterQuickLinks.scss';

const defaultProps = {
    className: '',
    heading: '',
    headingUrl: {},
    id: '1',
    listing: [],
    showLinkedInFollow: false,
};

const propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    headingUrl: PropTypes.objectOf(PropTypes.string),
    id: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
    showLinkedInFollow: PropTypes.bool,
};

function FooterQuickLinks({
    className,
    heading,
    headingUrl,
    id,
    listing,
    showLinkedInFollow,
}) {
    return (
        <>
            <nav aria-labelledby={`footer-quick-links-${id}`} className={`m-footer-quick-links ${className}`}>
                {(heading && !headingUrl) && (
                    <strong className="m-footer-quick-links__heading" id={`footer-quick-links-${id}`}>{heading}</strong>
                )}
                {(heading && headingUrl) && (
                    <strong className="m-footer-quick-links__heading" id={`footer-quick-links-${id}`}>
                        <Link className="m-footer-quick-links__link" activeClassName="is-active" to={headingUrl}>{heading}</Link>
                    </strong>
                )}
                <ul className="m-footer-quick-links__list" role="menubar">
                    {listing.map(node => {
                        const linkText = node?.fo_quick_links_link_text?.text;
                        const linkUrl = node?.fo_quick_links_link_url;

                        return (
                            <li className="m-footer-quick-links__item" key={linkText} role="menuitem">
                                {(linkText && linkUrl) && (
                                    <Link className="m-footer-quick-links__link" partiallyActive to={linkUrl}>{linkText}</Link>
                                )}
                            </li>
                        );
                    })}
                    {showLinkedInFollow && (
                        <li dangerouslySetInnerHTML={{
                            __html: `<script src="https://platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script><script type="IN/FollowCompany" data-id="10475772" data-counter="bottom"></script>`
                        }} />
                    )}
                </ul>
            </nav>
        </>
    );
}

FooterQuickLinks.propTypes = propTypes;
FooterQuickLinks.defaultProps = defaultProps;

export default FooterQuickLinks;
