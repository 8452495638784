import React from 'react';
import PropTypes from 'prop-types';

import Button from 'atoms/button/Button';
import IconBurger from 'atoms/icon/Burger';
import IconChevronDown from 'atoms/icon/ChevronDown';
import IconClose from 'atoms/icon/Close';
import Link from 'atoms/link/Link';
import NavDropdown from 'molecules/navdropdown/NavDropdown';

import './Nav.scss';

const defaultProps = {
	className: '',
	menu: [],
};

const propTypes = {
	activeDropdown: PropTypes.string,
	activeDropdownClassName: PropTypes.string,
	className: PropTypes.string,
	handleActiveDropdown: PropTypes.func.isRequired,
	isMenuOpen: PropTypes.bool.isRequired,
	menu: PropTypes.arrayOf(PropTypes.object),
	menuOpenClassName: PropTypes.string.isRequired,
	menuToggleButtonText: PropTypes.string.isRequired,
	toggleMenu: PropTypes.func.isRequired,
};

function Nav({
	activeDropdown,
	activeDropdownClassName,
	className,
	handleActiveDropdown,
	isMenuOpen,
	menu,
	menuOpenClassName,
	menuToggleButtonText,
	toggleMenu,
}) {
	return (
		<>
			<nav className={`m-nav ${className}`} aria-label="Main menu">
				<Button aria-label={menuToggleButtonText} className={`a-button--unstyled a-button--small m-nav__menu-toggle-button ${menuOpenClassName}`} iconOnly={true} onClick={toggleMenu}>
					{!isMenuOpen && (
						<IconBurger className="m-nav__menu-toggle-icon" />
					)}
					{isMenuOpen && (
						<IconClose className="m-nav__menu-toggle-icon" />
					)}
				</Button>

				<div className={`m-nav__menu ${menuOpenClassName}`}>
					<div className="m-nav__menu-inner">
						<ul className="m-nav__menu-list" role="menubar">
							{menu.map(node => {
								const navId = node?.text.replace(/\s+/g, '-').toLowerCase();
								const navDropdown = node?.dropdown;
								const navDropdownDescription = navDropdown?.description;
								const navDropdownLinkText = navDropdown?.linkText;
								const navDropdownLinkUrl = navDropdown?.linkUrl;
								const navDropdownLinks1Heading = navDropdown?.dropdownLinks1Heading;
								const navDropdownLinks1Listing = navDropdown?.dropdownLinks1Listing;
								const navDropdownLinks2Heading = navDropdown?.dropdownLinks2Heading;
								const navDropdownLinks2Listing = navDropdown?.dropdownLinks2Listing;
								const navDropdownLinks3Heading = navDropdown?.dropdownLinks3Heading;
								const navDropdownLinks3Listing = navDropdown?.dropdownLinks3Listing;
								const navText = node?.text;
								const navUrl = node?.url;
								const navDropdownActive = activeDropdown === navId;
								const navDropdownActiveClassName = navDropdownActive ? activeDropdownClassName : '';

								return (
									<li className="m-nav__menu-item" key={navId} role="menuitem">
										<div className="m-nav__menu-link-container">
											{navDropdown && (
												<Button className={`a-button--unstyled m-nav__menu-link ${navDropdownActiveClassName}`} onClick={handleActiveDropdown} id={navId} type="button">
													{navText}
													{navDropdownActive && (
														<IconClose className="m-nav__menu-link-dropdown-icon" />
													)}
													{!navDropdownActive && (
														<IconChevronDown className="m-nav__menu-link-dropdown-icon" />
													)}
												</Button>
											)}
											{!navDropdown && (
												<Link className="m-nav__menu-link" to={navUrl}>{navText}</Link>
											)}
										</div>

										{navDropdown && (
											<div className={`m-nav__dropdown-container ${navDropdownActiveClassName}`}>
												<NavDropdown
													className="m-nav__dropdown"
													description={navDropdownDescription}
													handleActiveDropdown={handleActiveDropdown}
													linkText={navDropdownLinkText}
													linkUrl={navDropdownLinkUrl}
													dropdownLinks1Heading={navDropdownLinks1Heading}
													dropdownLinks1Listing={navDropdownLinks1Listing}
													dropdownLinks2Heading={navDropdownLinks2Heading}
													dropdownLinks2Listing={navDropdownLinks2Listing}
													dropdownLinks3Heading={navDropdownLinks3Heading}
													dropdownLinks3Listing={navDropdownLinks3Listing}
												/>
											</div>
										)}
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}

Nav.propTypes = propTypes;
Nav.defaultProps = defaultProps;

export default Nav;
