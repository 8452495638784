import React from 'react';
import PropTypes from 'prop-types';

import IconEdit from 'atoms/icon/Edit';
import IconNavigation from 'atoms/icon/Navigation';
import IconServer from 'atoms/icon/Server';
import IconSettings from 'atoms/icon/Settings';
import IconTarget from 'atoms/icon/Target';
import IconUsers from 'atoms/icon/Users';
import Link from 'atoms/link/Link';

import './NavDropdownLinks.scss';

const defaultProps = {
    className: '',
    heading: '',
    listing: [],
    liveText: 'Live',
};

const propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
    liveText: PropTypes.string,
};

function NavDropdownLinks({
    className,
    heading,
    listing,
    liveText,
}) {
    function renderIcon(name) {
        switch(name) {
            case 'Edit':
                return <IconEdit />;
            case 'Navigation':
                return <IconNavigation />;
            case 'Server':
                return <IconServer />;
            case 'Settings':
                return <IconSettings />;
            case 'Target':
                return <IconTarget />;
            case 'Users':
                return <IconUsers />;
            default:
                return '';
        }
    }

    return (
        <>
            <div className={`m-nav-dropdown-links ${className}`}>
                {heading && (
                    <strong className="m-nav-dropdown-links__heading">{heading}</strong>
                )}
                <ul className="m-nav-dropdown-links__list" role="menubar">
                    {listing.map(node => {
                        const linkDescription = node?.description;
                        const linkIcon = node?.icon;
                        const linkLive = node?.live;
                        const linkText = node?.text;
                        const linkUrl = node?.url;

                        return (
                            <li className="m-nav-dropdown-links__item" key={linkText} role="menuitem">
                                {(linkText && linkUrl) && (
                                    <Link className="m-nav-dropdown-links__link" partiallyActive={true} to={linkUrl}>
                                        {linkIcon && (
                                            <span className="m-nav-dropdown-links__icon">
                                                {renderIcon(linkIcon)}
                                            </span>
                                        )}
                                        <span className="m-nav-dropdown-links__link-text">{linkText}</span>
                                        {linkLive && (
                                            <span className="m-nav-dropdown-links__live">{liveText}</span>
                                        )}
                                        {linkDescription && (
                                            <p className="m-nav-dropdown-links__description">{linkDescription}</p>
                                        )}
                                    </Link>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

NavDropdownLinks.propTypes = propTypes;
NavDropdownLinks.defaultProps = defaultProps;

export default NavDropdownLinks;