import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import * as JsSearch from 'js-search';

import Link from 'atoms/link/Link';
import FormGroupInput from 'molecules/formgroupinput/FormGroupInput';

import './FormSearch.scss';

const defaultProps = {
    className: '',
    searchText: 'Search',
};

const propTypes = {
    className: PropTypes.string,
    searchText: PropTypes.string,
};

function returnPageType(type, parentUid, uid) {
    const label = (parentUid)? parentUid : uid;

    switch(type) {
        case 'blog_article':
        case 'blog_listing':
            return 'blog';
        case 'content':
            return label;
        default:
            return '';
    }
}

const FormSearch = forwardRef(({
    className,
    searchText,
}, ref) => {
    const searchData = "/data-search.json";
    const [state, setState] = useState({
        isError: false,
        isLoading: true,
        pages: [],
        search: [],
        searchQuery: '',
        searchResults: [],
    });

    useEffect(function() {
        Axios.get(searchData)
        .then(result => {
            const searchData = result.data;
            setState((previousState) => {
                return {
                    ...previousState,
                    pages: searchData.pages,
                }
            });
        })
        .catch(err => {
            setState((previousState) => {
                return {
                    ...previousState,
                    isError: true,
                }
            });
            console.log(`Something bad happened while fetching the data\n${err}`);
        });
    }, []);

    useEffect(() => {
        rebuildIndex();
    }, [state.pages])

    // Rebuilds the overall index based on the options
    function rebuildIndex() {
        const dataToSearch = new JsSearch.Search("id");
        dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
        dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
        dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id");

         // Sets the index attribute for the data
        dataToSearch.addIndex("mastheadDescription");
        dataToSearch.addIndex("mastheadTitle");
        dataToSearch.addIndex("metaDescription");
        dataToSearch.addIndex("metaTitle");
        dataToSearch.addIndex("uid");

        // Adds the data to be searched
        dataToSearch.addDocuments(state.pages);
        setState((previousState) => {
            return {
                ...previousState,
                isLoading: false,
                search: dataToSearch,
            }
        });
    }

    // Handles the input change and perform a search with js-search in which the results will be added to the state
    function handleInputChange(event) {
        const searchValue = event.target.value;
        const queryResult = state.search.search(searchValue);
        setState((previousState) => {
            return {
                ...previousState,
                searchQuery: searchValue,
                searchResults: queryResult,
            }
        });
    }

    function handleSubmit(event) {
        event.preventDefault();
    }

    return (
        <>
            <div className={`o-form-search ${className}`}>
                <form className="o-form-search__form" onSubmit={handleSubmit}>
                    <FormGroupInput
                        className="o-form-search__input-field"
                        id="search-field"
                        label="Search"
                        name="search"
                        onChange={handleInputChange}
                        ref={ref}
                        placeholder="Search..."
                        required={true}
                    />
                </form>

                {state.searchResults.length > 0 && (
                    <div className="o-form-search__results">
                        <span className="o-form-search__results-count">Results: {state.searchResults.length}</span>

                        <ul className="o-form-search__results-list">
                            {state.searchResults.map(item => {
                                const itemId = item?.id;
                                const itemText = item?.metaTitle || item?.mastheadTitle;
                                const itemType = returnPageType(item?.type, item?.parentUid, item?.uid);
                                const itemUrl = {
                                    link_type: 'Document',
                                    type: item?.type,
                                    uid: item?.uid,
                                };

                                return (
                                    <li className={`o-form-search__results-item o-form-search__results-item--${itemType}`} key={`{${itemId}}-search-item`}>
                                        <span className="o-form-search__results-link-container">
                                            <Link className="o-form-search__results-link" to={itemUrl}>
                                                {itemText}
                                            </Link>
                                        </span>
                                        <span className="o-form-search__results-type">{itemType}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </>
    );
});

FormSearch.propTypes = propTypes;
FormSearch.defaultProps = defaultProps;

export default FormSearch;