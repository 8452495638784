import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import './Input.scss';

const defaultProps = {
    className: '',
    disabled: false,
    onChange: () => {},
    placeholder: undefined,
    required: false,
    type: 'text',
    value: undefined,
};

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.string,
};

const Input = forwardRef(({
    className,
    disabled,
    id,
    name,
    onChange,
    placeholder,
    required,
    type,
    value,
    ...rest
}, ref) => (
    <>
        <input className={`a-input ${className}`} disabled={disabled} id={id} name={name} onChange={onChange} placeholder={placeholder} ref={ref} required={required} type={type} value={value} {...rest} />
    </>
));

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;