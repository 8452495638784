import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const defaultProps = {
    className: '',
    title: '',
};

const propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

function IconEdit({
    className,
    title,
}) {
    return (
        <>
            <svg className={`a-icon a-icon--fill ${className}`} viewBox="0 0 1080 1080">
                {title && (
                    <title>{title}</title>
                )}
                <path d="M1068.2 840.6H11.8l184.1-184.1V347.1C195.9 157 349.9 2.9 540 2.9 730.1 2.9 884.1 157 884.1 347v309.5l184.1 184.1z" />
                <path d="M432.9 818.5h214.2v214.2H432.9z" transform="rotate(45.001 540.013 925.618)"/>
            </svg>
        </>
    );
}

IconEdit.propTypes = propTypes;
IconEdit.defaultProps = defaultProps;

export default IconEdit;
